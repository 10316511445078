.form-group {
    margin-bottom: 20px;
  }
  
  .form-group label {
    display: block;
    margin-bottom: 5px;
  }
  
  .form-group input,
  .form-group textarea {
    width: 100%;
    padding: 10px;
    font-size: 16px;
    border-radius: 5px;
    border: 1px solid #777;
    background-color: #333;
    color: #fff;
  }
  
  .form-group textarea {
    resize: vertical;
  }
  
  .Envoie {
    background-color: rgb(255, 0, 204);
    color: #fff;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    font-size: 16px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .Envoie:hover {
    background-color: rgba(255, 0, 204, 0.342);
  }

  