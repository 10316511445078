body{
    color: white;
    background-color: #1F1F1F;
}

nav{
    padding: 20px;
}

nav ul {
    list-style-type: none;
    display: flex;
    cursor: pointer;
}


nav ul li {
    margin-right: 20px;
    margin-top: 20px;
}

nav ul li a {
    text-decoration: none;
    color: #fff;
}

.unactive {
    color: #fff;
}

.active {
    color: rgb(192, 12, 180);
    content: '';
    display: block;
    height: 2px;
    transition: width 0.3s;
    width: 100%;
    text-decoration: none;
}

.unselect {
    display: none;
}

::-webkit-scrollbar {
    width: 10px;
    background-color: transparent; 
  }
  
  ::-webkit-scrollbar-thumb {
    background-color: #7d057d; 
    border-radius: 5px; 
  }
  
  ::-webkit-scrollbar-thumb:hover {
    background-color: #ff3399b7;
  }
  

  









